%screen {
  font-weight: 300;
  font-size: 15px;
  padding: 24px 32px;
  margin-top: 20px;

  h3 {
    margin: 10px 0;
    color: $notBlack;
    font: normal normal bold 24px/36px Rubik;
  }

  h4 {
    margin: 10px 0;
    color: $notBlack;
    font-weight: 500;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.empty-list {
  padding: 30px;
  font-size: 20px;
}

.blue-link {
  color: $primary;
  cursor: pointer;
  user-select: none;
}

.view-title {
  padding: 1.5rem 1.9rem;
  background: $contentWhite;

  h3 {
    margin: 0;
    font: normal normal bold 24px/36px Rubik;
  }
}

.view-title.stuck {
  position: sticky;
  z-index: 100;
  top: 60px;
  margin-bottom: 0;
  box-shadow: 0 3px 6px $shadowGraySmooth;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-icon {
  margin-right: 12px;
}
