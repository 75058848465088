.project,
.projects {
  @extend %screen;

  .project-title,
  .projects-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .projects-actions {
      display: flex;
      gap: 8px;
    }
  }

  h4 {
    font: normal normal bold 18px/22px Rubik;
    margin-block: 3rem 0;
  }
}

.projects-edit-mode {
  padding: 0;
}
