$notBlack: #131523;
$switchGrey: #707997;
$gray: #dedede;
$lightGray: #f2f2f2;
$lighterGray: #fafafa;
$subtitleGray: #7e84a3;
$shadowGray: #15223214;
$shadowGraySmooth: #00000029;
$mainBackground: #f4f5fc;
$buttonBorderGray: #d7dbec;
$boxGray: #d0d6e8;
$inputGray: #8b8b8b;
$inputGrayBorder: #e0e0e0;
$disabledGray: #efefef;
$white: #ffffff;
$contentWhite: #f4f7fc;

$darkBlue: #1e385a;
$primary: #4877b4;
$lightPrimary: #5e91da;
$lightAquaGreen: #33abaf;

$red: #c54343;
$inputErrorRed: #ff0000;
$errorRed: #f44336;
$purple: #b45db7;

$green: #4caf50;
$aquaGreen: #1fd286;
$aquaGreenTransparent: #1fd2861a;

$orange: #e59c2e;
$yellow: #e5aa51;
$lightYellow: #ffeb3b;

$plexoBlue: #256fb5;
$plexoGreen: #cecf00;

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
