.table-paper-container {
  padding: 24px;
  box-shadow: 0 1px 4px $shadowGray !important;
}

.table-container {
  background-color: white;
  max-width: 100%;

  .table {
    border: none;
    table-layout: fixed;

    .table-head {
      background-color: $contentWhite;

      .table-head-cell {
        padding: 24px 0;
        color: $subtitleGray;
        font: normal normal bold 14px/16px rubik;
        border: none;
      }

      .actions-head-cell {
        width: 120px;
      }
      .action-delete-head-cell {
        width: 60px;
      }
    }

    .table-row {
      background-color: white;
      transition: background-color 500ms;
      border: none;

      &:hover {
        background-color: $mainBackground;
        cursor: pointer;
        padding: 5;
      }

      .table-cell {
        border: none;
        font: normal normal normal 14px/16px rubik;
      }

      .multiline-cell {
        word-wrap: break-word;
      }

      .rounded-icon {
        height: 24px;
        width: 24px;
        background-color: $contentWhite;
        padding: 6px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }

      .actions-cell {
        width: 120px;
      }

      .action-delete-cell {
        width: 60px;
      }

      .icons-row-cell {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: flex-end;

        .squared-icon {
          height: 20px;
          width: 20px;
          margin: 0 4px;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $buttonBorderGray;
          border-radius: 4px;

          &:hover {
            background-color: $buttonBorderGray;
          }
        }
      }
    }
  }
}
