.form {
  display: flex;
  flex-direction: column;

  .form-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .form-secret {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
  }

  .inline-form-line {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 0;
  }

  .title {
    margin-top: 15px;

    h2 {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 15px 10px;
  }

  .edit-icon {
    color: $primary;
  }

  .delete-icon {
    color: $red;
  }

  .save {
    margin: 0 15px 15px 0;
    font-size: 16px;
  }

  .form-inline-text {
    margin: 0 0 10px;
    font-size: 14px;
  }
}
