//#region Dialogs

div.MuiBackdrop-root {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}

//#region MUI Card

.card-container.MuiPaper-root {
  background-color: $white;
  box-shadow: 0 1px 4px $shadowGray;
  padding: 2rem 1.75rem;
}

//#endregion

//#region MUI Paper

.paper {
  padding: 32px;
  margin-bottom: 12px;
}

.paper-edit-mode {
  padding: 0;
}

//#endregion

//#region MUI Switch

.switch.MuiSwitch-root {
  padding: 6px 12px 8px 0;

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
  }

  .MuiSwitch-track {
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid $switchGrey;
  }

  .MuiSwitch-switchBase {
    padding: 9px 3px;
    color: $subtitleGray;

    &.MuiSwitch-colorPrimary.Mui-checked {
      color: $white;

      & + .MuiSwitch-track {
        background-color: $plexoGreen;
        border-color: $plexoGreen;
      }
    }
  }
}

//#endregion

//#region MUI FormControl

.MuiFormControl-root {
  position: relative;
}

//#region MUI InputLabel

.input-label {
  &.MuiFormLabel-root,
  &.MuiFormLabel-root.Mui-focused {
    color: $subtitleGray;
    left: -10px;
    font: normal normal normal 18px/20px Rubik;
  }
  &.MuiInputLabel-outlined {
    pointer-events: auto;
  }
}

//#endregion

//#region MUI Inputs: Select and TextField

%input {
  padding-block: 0.6rem;
  color: $inputGray;
  background-color: $white;
  font: normal normal normal 14px/17px Rubik;
}

.MuiOutlinedInput-root {
  margin-block: 1rem 1rem;
  border-radius: 5px;

  input,
  .MuiSelect-select {
    @extend %input;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid $inputGrayBorder;
    border-radius: 5px;
  }

  &:hover fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $switchGrey;
  }
}

.search-input {
  &.MuiOutlinedInput-root,
  & > .MuiOutlinedInput-root {
    margin-block: 0;
  }
}

.expandable-input {
  border: none;

  &.MuiOutlinedInput-root,
  & > .MuiOutlinedInput-root {
    margin-block: 0;
  }

  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-radius: 5px;
    border: 1px solid $inputGrayBorder;
    cursor: pointer;
  }

  .MuiSelect-select {
    border: none;

    & ~ fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

.input-element.MuiSelect-select {
  @extend %input;
  padding-inline: 1rem 2rem;

  option {
    color: $inputGray;
  }
}

.Mui-InputBase-input:focus {
  outline: none;
  padding: 3rem;
}

.Mui-disabled {
  &.input-disabled,
  &.MuiOutlinedInput-root,
  &.MuiSelect-select {
    font: normal normal normal 14px/17px Rubik;
    color: $inputGray;
    background-color: $disabledGray;
    border-color: $inputGrayBorder;
  }
}

.input-error {
  &.Mui-error,
  &.MuiFormHelperText-root {
    color: $inputErrorRed;
  }

  &.Mui-error {
    border-color: $inputErrorRed;
  }

  &.MuiFormHelperText-root {
    font: normal normal normal 14px/17px Rubik;
    position: absolute;
    bottom: -0.5rem;
    left: 0;
  }
}

//#endregion

//#region MuiTabs

.projects {
  .MuiTabs-indicator {
    background-color: transparent;
  }

  .MuiTab-root {
    text-transform: none;
    font: normal normal normal 14px Rubik;
  }

  .MuiTab-textColorInherit {
    color: $subtitleGray;
  }

  button.Mui-selected {
    color: $notBlack;
    font-weight: bold;
  }
}

//#endregion
