.environment,
.environments {
  @extend %screen;

  .environment-title,
  .environments-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .environments-actions {
      display: flex;
      gap: 8px;
    }
  }

  h4 {
    font: normal normal bold 18px/22px Rubik;
    margin-block: 3rem 0;
  }
}

.environments-edit-mode {
  padding: 0;
}

.environments-back-button {
  cursor: pointer;
  margin-right: 12px;
}
